// Code generated by codegen. DO NOT EDIT!
//
// Use 'yarn codegen' to regenerate this file based on schema and
// file defined operations.

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

import * as Types from "../../../gql/types";

import { fetcher } from "lib/client/graphql";
export type UseAssetForCurrentPageReservationQueryVariables = Types.Exact<{
  reservationId: Types.Scalars["ID"]["input"];
}>;

export type UseAssetForCurrentPageReservationQuery = {
  __typename?: "Query";
  reservation: {
    __typename?: "Reservation";
    id: string;
    asset: { __typename?: "Asset"; id: string };
  } | null;
};

export type UseAssetForCurrentPagePurchaseQueryVariables = Types.Exact<{
  purchaseId: Types.Scalars["ID"]["input"];
}>;

export type UseAssetForCurrentPagePurchaseQuery = {
  __typename?: "Query";
  purchase: {
    __typename?: "Purchase";
    id: string;
    asset: { __typename?: "Asset"; id: string } | null;
  } | null;
};

export const UseAssetForCurrentPageReservationDocument = `
    query UseAssetForCurrentPageReservation($reservationId: ID!) {
  reservation(id: $reservationId) {
    id
    asset {
      id
    }
  }
}
    `;
export const useUseAssetForCurrentPageReservationQuery = <
  TData = UseAssetForCurrentPageReservationQuery,
  TError = unknown,
>(
  variables: UseAssetForCurrentPageReservationQueryVariables,
  options?: UseQueryOptions<
    UseAssetForCurrentPageReservationQuery,
    TError,
    TData
  >,
) =>
  useQuery<UseAssetForCurrentPageReservationQuery, TError, TData>(
    ["UseAssetForCurrentPageReservation", variables],
    fetcher<
      UseAssetForCurrentPageReservationQuery,
      UseAssetForCurrentPageReservationQueryVariables
    >(UseAssetForCurrentPageReservationDocument, variables),
    options,
  );

useUseAssetForCurrentPageReservationQuery.getKey = (
  variables: UseAssetForCurrentPageReservationQueryVariables,
) => ["UseAssetForCurrentPageReservation", variables];

export const useInfiniteUseAssetForCurrentPageReservationQuery = <
  TData = UseAssetForCurrentPageReservationQuery,
  TError = unknown,
>(
  pageParamKey: keyof UseAssetForCurrentPageReservationQueryVariables,
  variables: UseAssetForCurrentPageReservationQueryVariables,
  options?: UseInfiniteQueryOptions<
    UseAssetForCurrentPageReservationQuery,
    TError,
    TData
  >,
) => {
  return useInfiniteQuery<
    UseAssetForCurrentPageReservationQuery,
    TError,
    TData
  >(
    ["UseAssetForCurrentPageReservation.infinite", variables],
    (metaData) =>
      fetcher<
        UseAssetForCurrentPageReservationQuery,
        UseAssetForCurrentPageReservationQueryVariables
      >(UseAssetForCurrentPageReservationDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteUseAssetForCurrentPageReservationQuery.getKey = (
  variables: UseAssetForCurrentPageReservationQueryVariables,
) => ["UseAssetForCurrentPageReservation.infinite", variables];
export const UseAssetForCurrentPagePurchaseDocument = `
    query UseAssetForCurrentPagePurchase($purchaseId: ID!) {
  purchase(id: $purchaseId) {
    id
    asset {
      id
    }
  }
}
    `;
export const useUseAssetForCurrentPagePurchaseQuery = <
  TData = UseAssetForCurrentPagePurchaseQuery,
  TError = unknown,
>(
  variables: UseAssetForCurrentPagePurchaseQueryVariables,
  options?: UseQueryOptions<UseAssetForCurrentPagePurchaseQuery, TError, TData>,
) =>
  useQuery<UseAssetForCurrentPagePurchaseQuery, TError, TData>(
    ["UseAssetForCurrentPagePurchase", variables],
    fetcher<
      UseAssetForCurrentPagePurchaseQuery,
      UseAssetForCurrentPagePurchaseQueryVariables
    >(UseAssetForCurrentPagePurchaseDocument, variables),
    options,
  );

useUseAssetForCurrentPagePurchaseQuery.getKey = (
  variables: UseAssetForCurrentPagePurchaseQueryVariables,
) => ["UseAssetForCurrentPagePurchase", variables];
export const useInfiniteUseAssetForCurrentPagePurchaseQuery = <
  TData = UseAssetForCurrentPagePurchaseQuery,
  TError = unknown,
>(
  pageParamKey: keyof UseAssetForCurrentPagePurchaseQueryVariables,
  variables: UseAssetForCurrentPagePurchaseQueryVariables,
  options?: UseInfiniteQueryOptions<
    UseAssetForCurrentPagePurchaseQuery,
    TError,
    TData
  >,
) => {
  return useInfiniteQuery<UseAssetForCurrentPagePurchaseQuery, TError, TData>(
    ["UseAssetForCurrentPagePurchase.infinite", variables],
    (metaData) =>
      fetcher<
        UseAssetForCurrentPagePurchaseQuery,
        UseAssetForCurrentPagePurchaseQueryVariables
      >(UseAssetForCurrentPagePurchaseDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteUseAssetForCurrentPagePurchaseQuery.getKey = (
  variables: UseAssetForCurrentPagePurchaseQueryVariables,
) => ["UseAssetForCurrentPagePurchase.infinite", variables];
