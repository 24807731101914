// eslint-disable-next-line no-restricted-imports
import {
  GrowthBook,
  GrowthBookProvider,
  useFeature,
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from "@growthbook/growthbook-react";
import { useEffect, useMemo } from "react";

import { serverEnvironment } from "lib/environment";
import { useLanguage } from "lib/i18n/client";
import { TrackingContextPage } from "lib/tracking";

import { Session, useSession } from "./SessionContext";

const apiHost = "https://feature-flags.libere.tech";
const clientKeyDev = "sdk-kK2KE7g4VclGMewN";
const clientKeyProd = "sdk-PVzE604HSXubs6h";

const ExperimentContext: React.FC<ExperimentContextProps> = ({
  children,
  environment,
  trackingContext,
}) => {
  const lang = useLanguage();
  const growthbook = useMemo(() => initGrowhtbook(environment), [environment]);

  const { session } = useSession();
  useEffect(() => {
    initAttributes(growthbook, session, trackingContext);
  }, [session, trackingContext]);

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      locale: lang,
    });
  }, [lang]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

const useFeaturesReady = () => {
  const gb = useGrowthBook();
  return gb ? gb.ready : false;
};

const initGrowhtbook = (environment: string): GrowthBook =>
  new GrowthBook({
    apiHost,
    clientKey: environment === "production" ? clientKeyProd : clientKeyDev,
    enableDevMode: process.env.NODE_ENV === "development",
    trackingCallback: (experiment, result) => {
      setTimeout(() => {
        window.dataLayer.push({
          event: "experiment_seen",
          event_category: experiment.key,
          event_value: result.variationId,
        });
      }, 100); // Postpone until page view event is triggered
    },
  });

const initAttributes = (
  growthbook: GrowthBook,
  session: Session | null,
  trackingContext: TrackingContextPage | undefined,
) => {
  if (session?.anonId) {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      anonId: session.anonId,
      inhabitantId: session.inhabitantId,
      ...(trackingContext
        ? {
            purchaseId: trackingContext.purchase_id,
            reservationId: trackingContext.reservation_id,
            assetId: trackingContext.asset_id,
          }
        : undefined),
    });
    growthbook.loadFeatures({ autoRefresh: true });
  }
};

const ssrFeatureIsOn = async (id: string) => {
  const gb = initGrowhtbook(serverEnvironment());
  await gb.loadFeatures();

  const isOn = gb.isOn(id);

  gb.destroy();

  return isOn;
};

type ExperimentContextProps = {
  environment: string;
  trackingContext?: TrackingContextPage;
};

export {
  useFeature,
  useFeatureIsOn,
  useFeatureValue,
  useFeaturesReady,
  ssrFeatureIsOn,
};

export default ExperimentContext;
