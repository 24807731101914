/* eslint-env browser */
import * as Sentry from "@sentry/nextjs";

import { clientEnvironment } from "lib/environment";

Sentry.init({
  dsn: "https://c7f811c8f07d4691ae59742bed4112f7@o585252.ingest.sentry.io/5737524",
  environment: clientEnvironment(),
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  beforeSend(event) {
    if (
      typeof window !== "undefined" &&
      window.navigator.userAgent.match("HeadlessChrome")
    ) {
      return null;
    }

    return event;
  },
});
