import { IncomingMessageWithFullContext } from "types";

import { identityCookieName } from "./identity";

const endpoint = "/public/v3/graphql";

const requestInit: RequestInit = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const ssrFetcher = <TData, TVariables>(
  req: IncomingMessageWithFullContext & {
    cookies?: { [key: string]: string };
  },
  locale: string,
  query: string,
  variables: TVariables,
) => {
  const authToken = req?.cookies?.[identityCookieName];
  return fetcher<TData, TVariables>(query, variables, {
    host: `${req?.context.brand.baseUrl || ""}`,
    requestInit: {
      headers: {
        ...requestInit.headers,
        Authorization: authToken ? `Bearer ${authToken}` : "",
        "Accept-Language": locale,
      },
    },
  });
};

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: {
    host?: string;
    requestInit?: RequestInit;
  },
) => {
  return async (): Promise<TData> => {
    const clientSideHeaders = (
      typeof window != "undefined" && window.location.pathname
        ? {
            "Accept-Language":
              window.location.pathname.match(/^\/(..)\//)?.[1] || "en",
          }
        : {}
    ) as { "Accept-Language": string } | Record<string, never>;

    const res = await fetch(`${options?.host || ""}${endpoint}`, {
      method: "POST",
      ...requestInit,
      ...options?.requestInit,
      headers: {
        ...requestInit.headers,
        ...options?.requestInit?.headers,
        ...clientSideHeaders,
      },
      body: JSON.stringify({ query, variables }),
    });

    const isMutation = query.trimStart().startsWith("mutation");
    const { data, errors, extensions } = await res.json();

    if (errors && (!data || isMutation)) {
      throw new GraphqlErrors(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors.map((error: { [key: string]: any }) => {
          const { message, path, extensions } = error;
          return new GraphqlError(message, path, extensions);
        }),
      );
    }

    return new GraphqlResponse<TData>(
      data,
      errors
        ? new GraphqlErrors(
            errors?.map(
              (error: GraphqlError) =>
                new GraphqlError(error.message, error.path, error.extensions),
            ),
          )
        : undefined,
      extensions,
    ) as TData;
  };
};

export function isGQLErrorAtPath<TData>(v: TData, path: GraphqlErrorPath) {
  return v instanceof GraphqlResponse && v.errorsAtPath(path).length > 0;
}

export function getGQLErrorAtPath<TData>(v: TData, path: GraphqlErrorPath) {
  return v instanceof GraphqlResponse ? v.errorsAtPath(path) : [];
}

export function getExtension<TData, T>(v: TData, key: string): T | undefined {
  return v instanceof GraphqlResponse ? v.extension<T>(key) : undefined;
}

export class GraphqlResponse<T> {
  constructor(
    data: T,
    private $errors: GraphqlErrors | undefined,
    private $extensions: GraphqlExtensions | undefined,
  ) {
    Object.assign(this, data);
  }

  errorsAtPath(path: GraphqlErrorPath): GraphqlError[] {
    if (!this.$errors || (this.$errors.errors || []).length == 0) {
      return [];
    }

    return this.$errors.errors.filter(
      (error) =>
        error.path?.length == path.length &&
        error.path?.every((p, i) => p === path[i]),
    );
  }

  extension<T>(key: string): T | undefined {
    return this.$extensions?.[key] as T;
  }
}

export type GraphqlErrorPath = Array<string | number>;
export type GraphqlExtensions = {
  code?: string;
  [key: string]: unknown;
};

export class GraphqlErrors extends Error {
  public readonly errors: GraphqlError[];

  constructor(errors: GraphqlError[]) {
    if (errors.length === 0) {
      throw new Error("GraphqlErrors must have at least one error");
    }

    const firstError = errors[0];
    super(`${firstError.message} and (${errors.length - 1}) more errors`);
    this.errors = errors;
  }
}

export class GraphqlError extends Error {
  public readonly message: string;
  public readonly path?: GraphqlErrorPath;
  public readonly extensions?: GraphqlExtensions;

  constructor(
    message: string,
    path?: GraphqlErrorPath,
    extensions?: GraphqlExtensions,
  ) {
    super(message);
    this.message = message;
    this.path = path;
    this.extensions = extensions;
  }
}
