// Compatibility layer between Design System and Next.js due to Next.js limitations:
//     https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component

import Button, { ButtonProps } from "@liberetech/design-system/Button";
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from "next/link";
import { forwardRef } from "react";

// eslint-disable-next-line no-restricted-imports
import NextLink from "components/NextLink/NextLink";

const NextLinkButton: React.FC<ButtonProps & LinkProps> = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  children,
  ...rest
}) => (
  <NextLink
    href={href}
    as={as}
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    passHref={typeof passHref === "undefined" ? true : passHref}
    prefetch={prefetch}
    locale={locale}
  >
    <RefButton {...rest}>{children}</RefButton>
  </NextLink>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RefButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, any>(
  // Prevent NextLink's passHref reaching button component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, href, onClick, passHref, ...props }, ref) => (
    <Button href={href} onClick={onClick} buttonRef={ref} {...props}>
      {children}
    </Button>
  ),
);

export default NextLinkButton;
