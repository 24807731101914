import { useRouter } from "next/router";
import useSWRImmutable from "swr/immutable";

import { useBrand } from "components/Context/BrandContext";
import { cmsClientSwr } from "lib/client/cms";
import { useLanguage } from "lib/i18n/client";

import {
  useUseAssetForCurrentPagePurchaseQuery,
  useUseAssetForCurrentPageReservationQuery,
} from "./useAssetForCurrentPage.generated";

const useAssetForCurrentPage = (): {
  id: string;
  phone: string | null;
} | null => {
  const { pathname, query } = useRouter();

  const reservationId = pathname.startsWith("/myplace/reservation/[id]")
    ? query.id
    : null;
  const { data: dataReservation } = useUseAssetForCurrentPageReservationQuery(
    { reservationId: reservationId as string },
    { enabled: !!reservationId },
  );

  const purchaseId = pathname.startsWith("/myplace/purchase/[id]")
    ? query.id
    : null;
  const { data: dataPurchase } = useUseAssetForCurrentPagePurchaseQuery(
    { purchaseId: purchaseId as string },
    { enabled: !!purchaseId },
  );

  const assetId =
    dataReservation?.reservation?.asset.id ||
    dataPurchase?.purchase?.asset?.id ||
    (query.assetId as string);

  const assetSlug = pathname.startsWith("/asset/[slug]")
    ? (query.slug as string)
    : null;
  const asset = useAsset(
    assetSlug ? { slug: assetSlug } : assetId ? { id: assetId } : {},
  );

  return asset;
};

const useAsset = (
  options: { slug: string } | { id: string } | Record<string, never>,
): { id: string; phone: string | null } | null => {
  const brand = useBrand();
  const lang = useLanguage();

  const { data: asset } = useSWRImmutable<{
    asset: { properties: { id: string; contact: { phone: string | null } } };
  }>(
    "slug" in options
      ? assetBySlugQuery(`/${lang}/${options.slug}`)
      : "id" in options
        ? assetByIdQuery(options.id)
        : null,
    cmsClientSwr(brand.cmsUrl),
  );

  return asset && asset.asset
    ? {
        id: asset.asset.properties.id,
        phone: asset.asset.properties.contact.phone,
      }
    : null;
};

const assetBySlugQuery = (slug: string) => `{
	asset(id: "${slug}", idType: URI) {
	  properties { 
		id 
		contact {
		  phone
		}
	  }
	}
  }`;

const assetByIdQuery = (id: string) => `{
	asset: assetById(id: "${id}") {
	  properties {
		id
		contact {
		  phone
		}
	  }
	}
  }`;

export default useAssetForCurrentPage;
