import { createContext, useContext } from "react";

import { Brand } from "types";

const BrandContext = createContext<ContextType>({
  brand: null as unknown as Brand, // Brand is mandatory so no need to check for null
});

export const Provider: React.FC<{ brand: Brand }> = ({ children, brand }) => (
  <BrandContext.Provider value={{ brand }}>{children}</BrandContext.Provider>
);

export const useBrand: UseBrandType = () => {
  const context = useContext(BrandContext);
  return context?.brand || null;
};

type ContextType = { brand: Brand };
type UseBrandType = () => Brand;
