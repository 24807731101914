export const serverEnvironment = (): "staging" | "production" =>
  process.env.NODE_ENV === "production"
    ? process.env.DATA_ENV === "staging"
      ? "staging"
      : "production"
    : "staging";

export const clientEnvironment = (): "staging" | "production" =>
  typeof window !== "undefined" && window.location.host.endsWith(".xyz")
    ? "staging"
    : "production";
