import { ServerResponse } from "http";

import { captureException } from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";

import {
  IncomingMessageWithContext,
  IncomingMessageWithFullContext,
} from "types";

const application = {
  name: "web",
  type: "web",
  version: process.env.VERSION || "unknown",
};

const buildBaseMessage = (
  message: string,
  severity: LogSeverity,
  req?: IncomingMessageWithContext | IncomingMessageWithFullContext,
) => ({
  message,
  severity,
  data: {
    application,
    context: req
      ? {
          ip: req.socket.remoteAddress,
          locale: "es-ES",
          traceId: req.context.requestId,
          assetId: "brand" in req.context ? req.context.brand.id : null,
        }
      : {},
  },
});

export const log = (
  message: string,
  severity: LogSeverity = LogSeverity.Info,
  req?: IncomingMessageWithContext,
): void => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(JSON.stringify(buildBaseMessage(message, severity, req)));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureError = (err: any, captureContext?: CaptureContext) => {
  captureException(err, captureContext);
};

export const logHttpRequest = (
  req: IncomingMessageWithContext,
  res: ServerResponse,
  err?: Error,
): void => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const { headers, method, socket, url, context } = req;
  const { statusCode } = res;
  const { remoteAddress } = socket;
  const message = err?.stack || err?.message || "Request completed";
  const severity =
    statusCode >= 500
      ? LogSeverity.Error
      : statusCode >= 400
        ? LogSeverity.Warning
        : LogSeverity.Info;

  // eslint-disable-next-line no-console
  console.log(
    JSON.stringify({
      ...buildBaseMessage(message, severity, req),
      ...(severity === LogSeverity.Error
        ? {
            serviceContext: {
              service: application.name,
              version: application.version,
            },
            context: {
              httpRequest: {
                method: method,
                url: context.originalUrl || url,
                responseStatusCode: statusCode,
                userAgent: headers["user-agent"] || "n/a",
                remoteIp: remoteAddress,
              },
            },
          }
        : {}),
      httpRequest: {
        latency: `${(Date.now() - context.startTime) / 100}s`,
        requestUrl: context.originalUrl || url,
        status: statusCode,
        requestMethod: method,
        userAgent: headers["user-agent"] || "n/a",
        remoteIp: remoteAddress,
      },
    }),
  );
};

export enum LogSeverity {
  Debug = "DEBUG",
  Info = "INFO",
  Warning = "WARNING",
  Error = "ERROR",
  Critical = "CRITICAL",
}
