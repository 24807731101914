import { FormSelect } from "@liberetech/design-system";
import useSWRImmutable from "swr/immutable";

import { useBrand } from "components/Context/BrandContext";
import { cmsClientSwr } from "lib/client/cms";
import { useLanguage } from "lib/i18n/client";
import useForm from "lib/useForm";

const LayoutOnlineReceptionFormAssetSelector: React.FC<{
  id: string;
  onAssetSelected: (asset: { id: string; phone: string }) => void;
}> = ({ id, onAssetSelected }) => {
  const brand = useBrand();
  const lang = useLanguage();
  const { data } = useSWRImmutable<{
    assets: {
      nodes: {
        title: string;
        properties: {
          id: string;
          contact: { phone: string | null };
          closed: boolean;
        };
      }[];
    };
  }>(assetsQuery(lang), cmsClientSwr(brand.cmsUrl));
  const { inputProps, handleSubmit } = useForm<{ asset: string }>({
    translationContext:
      "common:onlineReception.newReservationSelectAsset.form.",
  });
  return (
    <form
      id={id}
      onSubmit={handleSubmit((formData) => {
        const asset = data?.assets.nodes.find(
          (asset) => asset.properties.id === formData.asset,
        );
        if (!asset) {
          return;
        }
        onAssetSelected({
          id: asset.properties.id,
          phone: asset.properties.contact.phone || "",
        });
      })}
    >
      <FormSelect {...inputProps("asset", { required: true })}>
        {data?.assets.nodes
          .filter(
            (asset) =>
              asset.properties.contact.phone && !asset.properties.closed,
          )
          .map((asset, i) => (
            <option key={i} value={asset.properties.id || ""}>
              {asset.title}
            </option>
          ))}
      </FormSelect>
    </form>
  );
};

const assetsQuery = (locale: string) => `{
  assets(first: 500, where: {language: ${locale.toUpperCase()}, orderby: {field: TITLE, order: ASC}}) {
    nodes {
      title
      properties {
        id
        contact {
          phone
        }
        closed
      }
    }
  }
}`;

export default LayoutOnlineReceptionFormAssetSelector;
