import { useRouter } from "next/router";

import { useBrand } from "components/Context/BrandContext";
import { useSession } from "components/Context/SessionContext";

const useSendMessageContext: UseSendMessageContext = () => {
  const { session } = useSession();
  const { pathname, query } = useRouter();
  const brand = useBrand();
  const baseContext = {
    isLoggedIn: !!session?.inhabitantId,
    brandId: brand.id,
    chainId: "libere_hospitality",
  };
  if (pathname.startsWith("/myplace/reservation")) {
    return {
      ...baseContext,
      reservationId: query.id as string,
    };
  }
  if (pathname.startsWith("/myplace/purchase")) {
    return {
      ...baseContext,
      purchaseId: query.id as string,
    };
  }
  return baseContext;
};

export type UseSendMessageContext = () => SendMessageContext;

export type SendMessageContext = {
  isLoggedIn: boolean;
  reservationId?: string;
  purchaseId?: string;
  brandId: string;
  chainId: string;
};

export default useSendMessageContext;
