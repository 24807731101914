import { NativeAppTrackingStatus } from "types";

export const isNativeApp = (): boolean =>
  typeof window !== "undefined" &&
  typeof window.ReactNativeWebView !== "undefined";

export const notifyNativeApp = (
  event: string,
  payload: { [key: string]: unknown } = {},
): void => {
  if (!isNativeApp()) {
    return;
  }

  window.ReactNativeWebView?.postMessage(JSON.stringify({ event, payload }));
};

export const nativeAppVersion = (): string | null =>
  isNativeApp() ? window?.nativeAppVersion || "1.0.0" : null;

export const nativeAppTrackingStatus = (): NativeAppTrackingStatus =>
  (isNativeApp() && window?.nativeAppTrackingStatus) || null;

export const isKiosk = (): boolean => {
  if (typeof window === "undefined") {
    return false;
  }

  return !!navigator.userAgent.match("; (EloView |Elo-)");
};

export const canShare = (): boolean =>
  typeof window != "undefined" &&
  // Disable sharing on MacOS due to a useless native share modal
  window.navigator.platform.toUpperCase().indexOf("MAC") == -1 &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  !!(window.navigator as any).canShare;
