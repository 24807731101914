import Prose from "@liberetech/design-system/Prose";
import useRipple from "@liberetech/design-system/useRipple";
import classNames from "classnames";
import React, { ReactNode, useRef } from "react";

import styles from "./ListItem.module.css";

const ListItem: React.FC<ListItemProps> = ({
  wrapperAs: Wrapper = "div",
  startElement,
  endElement,
  title,
  children,
  disabled,
  className,
  type,
}) => {
  const ref = useRef(null);
  useRipple(ref, { disabled });

  return (
    <li
      className={classNames(className, styles.listItem, {
        [styles.listItemNoChildren]: !children,
        [styles.listItemInfo]: type === "info",
        [styles.listItemError]: type === "error",
        [styles.listItemSuccess]: type === "success",
        [styles.listItemWarn]: type === "warn",
      })}
    >
      <Wrapper>
        <div className={styles.listItemWrap} ref={ref}>
          {startElement}
          <div className={styles.listItemContent}>
            <p className={styles.listItemTitle}>{title}</p>
            {children && (
              <Prose compact className={styles.listItemChildren}>
                {children}
              </Prose>
            )}
          </div>
          {endElement}
        </div>
      </Wrapper>
    </li>
  );
};

type ListItemProps = {
  wrapperAs?: keyof JSX.IntrinsicElements | React.FC;
  title: ReactNode | string;
  startElement?: ReactNode;
  endElement?: ReactNode;
  disabled?: boolean;
  className?: string;
  type?: "default" | "info" | "error" | "success" | "warn";
};

export default ListItem;
