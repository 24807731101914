import { TrackingCookieGroupV2, allCookieGroupsV2 } from "./tracking";
// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/no-unused-vars
let gtag = (..._args: any) => {
  return;
};

if (typeof window !== "undefined") {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/no-unused-vars
  gtag = function (..._args: any) {
    // eslint-disable-next-line prefer-rest-params
    window?.dataLayer?.push(arguments);
  };
}

export const updateGtagConsent = (
  mode: "default" | "update",
  trackingConfig: TrackingCookieGroupV2[] | null,
): void => {
  gtag("consent", mode, {
    ...allCookieGroupsV2.reduce(
      (prev, group) => ({
        ...prev,
        [group]: trackingConfig?.includes(group) ? "granted" : "denied",
      }),
      {},
    ),
    ...(mode === "default" ? { wait_for_update: 500 } : {}),
  });
};
