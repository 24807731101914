// This is a workaround for https://github.com/vercel/next.js/discussions/18419
// Should be removed once it's fixed.

// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { cloneElement } from "react";

const NextLink: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  href,
  locale,
  ...props
}) => {
  const { locale: routerLocale, defaultLocale } = useRouter();

  if (
    typeof href === "string" &&
    children &&
    (href?.startsWith("http") ||
      href?.startsWith("tel") ||
      href?.startsWith("#") ||
      href?.startsWith("mailto"))
  ) {
    return (
      <>
        {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
        {cloneElement(children as React.ReactElement<any>, { href, ...props })}
      </>
    );
  }

  if (
    locale === defaultLocale ||
    (locale !== false && routerLocale === defaultLocale)
  ) {
    return (
      <Link
        {...props}
        locale={false}
        href={`/${locale || routerLocale}${href}`}
        legacyBehavior
      >
        {children}
      </Link>
    );
  }

  return (
    <Link {...props} locale={locale} href={href} legacyBehavior>
      {children}
    </Link>
  );
};

export default NextLink;
