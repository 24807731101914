import { IncomingMessageWithFullContext } from "types";

import { HttpError } from "./bff";

const version = "v1";
export const identityCookieName = "li_auth";
const fetchIdentity: FetchIdentity = async (
  endpoint,
  { host, headers, ...rest },
) => {
  const response = await fetch(`${host}/public/${version}${endpoint}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    ...rest,
  });

  if (!response.ok) {
    throw new HttpError("Error fetching data from identity", response.status);
  }
  const json = await response.json();
  return {
    statusCode: response.status,
    ...json,
  };
};

export const identityClient: IdentityClient = (locale) =>
  configure({
    host: "",
    headers: {
      "Accept-Language": identityLocaleMap[locale] || identityLocaleMap["en"],
    },
  });

const identityLocaleMap: { [key: string]: string } = {
  es: "es-ES",
  en: "en-US",
  fr: "fr-FR",
  pt: "pt-PT",
};

export const identityServer: IdentityServer = (req) => {
  const authToken = req?.cookies?.[identityCookieName];
  return configure({
    host: req?.context.brand.baseUrl || "",
    headers: {
      ...(authToken ? { cookie: `${identityCookieName}=${authToken}` } : {}),
    },
  });
};

const configure: Configure = (config) => ({
  loginIntent: () => fetchIdentity("/auth/login-intent", config),
  login: (credentials, csrfToken, options) =>
    fetchIdentity("/auth/login", {
      ...config,
      method: "POST",
      body: JSON.stringify({
        ...credentials,
        ...options,
      }),
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    }),
  verifyEmail: (email) =>
    fetchIdentity("/auth/verify-email", {
      ...config,
      method: "POST",
      body: JSON.stringify({ email }),
    }),
  me: () => fetchIdentity("/auth/me", config),
  verifyToken: (token) =>
    fetchIdentity(`/auth/verify?token=${token}`, {
      ...config,
      method: "POST",
    }),
  register: (credentials) =>
    fetchIdentity("/auth/register", {
      ...config,
      method: "POST",
      body: JSON.stringify(credentials),
    }),
  logout: () => fetchIdentity("/auth/logout?no_redirect=true", config),
});

type FetchIdentity = <T>(
  endpoint: string,
  options: {
    host: string;
  } & RequestInit,
) => Promise<T>;

type Endpoints = {
  loginIntent: () => Promise<{ data: { csrfToken: string } }>;
  login: (
    credentials: LoginRequest,
    csrfToken: string,
    options: {
      skip2FA?: boolean;
      skipEmailVerification?: boolean;
      appLogin?: boolean;
      returnUrl: string;
    },
  ) => Promise<LoginResponse>;
  verifyEmail: (email: string) => Promise<VerifyEmailResponse>;
  me: () => Promise<MeResponse>;
  verifyToken: (token: string) => Promise<void>;
  register: (credentials: RegisterRequest) => Promise<void>;
  logout: () => Promise<Record<string, never>>;
};

type IdentityServer = (
  req: IncomingMessageWithFullContext & {
    cookies?: { [key: string]: string };
  },
) => Endpoints;

type IdentityClient = (locale: string) => Endpoints;

type Configure = (
  config: {
    host: string;
  } & RequestInit,
) => Endpoints;

export type LoginRequest = {
  magicToken?: string;
  confirmationNumber?: string;
  lastName?: string;
  otpToken?: string;
  email?: string;
  appLogin?: boolean;
};

export type RegisterRequest = {
  firstName: string;
  lastName: string;
  email: string;
  marketingCommunicationsAccepted: boolean;
  isInCommunity: boolean;
};

export type LoginResponse = {
  statusCode: number;
  data: {
    status?: string;
    redirect_url?: string;
    obfuscated_email?: string;
    app_access_token?: string;
  };
};

export type MeResponse = {
  data: {
    id: string;
    account_type: AccountType;
    account_id: string;
  };
};

export type AccountType =
  | "ACCOUNT_INHABITANT"
  | "ACCOUNT_COMPANY"
  | "ACCOUNT_TRAVEL_AGENCY";

export type VerifyEmailRequest = {
  email: string;
};

export type VerifyEmailResponse = {
  data: {
    status: string;
  };
};
