/* eslint-disable no-restricted-imports */
"use client";

import { TFunction } from "i18next";
import { useTranslation } from "next-i18next";

export { useTranslation, Trans } from "next-i18next";

export type Translate = TFunction;

export const useLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.language;
};
