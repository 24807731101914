import { useRouter } from "next/router";

const useOnlineReceptionScope = (): OnlineReceptionScope => {
  const { pathname } = useRouter();

  if (
    pathname.startsWith("/myplace") ||
    pathname.startsWith("/login") ||
    pathname.startsWith("/account")
  ) {
    return "stay";
  }

  if (
    pathname.startsWith("/faqs") ||
    pathname.startsWith("/online-reception")
  ) {
    return "faq";
  }

  return "directChannel";
};

type OnlineReceptionScope = "directChannel" | "stay" | "faq";

export default useOnlineReceptionScope;
