import { useRouter } from "next/router";
// eslint-disable-next-line no-restricted-imports
import { DefaultSeo as NextDefaultSeo } from "next-seo";

import { Brand } from "types";

export const DefaultSeo: React.FC<{
  brand: Brand;
  languageAlternates?: LanguageAlternates;
}> = ({ brand, languageAlternates }) => {
  const { asPath, locale } = useRouter();

  const alternates = languageAlternates
    ? languageAlternates.map((alternate) => ({
        ...alternate,
        href: `https://${brand.currentHost}${alternate.href}`,
      }))
    : brand.locales.map((alternateLocale) => ({
        href: `https://${brand.currentHost}/${alternateLocale}${asPath}`,
        hrefLang: alternateLocale,
      }));
  const xDefault =
    alternates?.find((alternate) => alternate.hrefLang === "en")?.href || "";

  return (
    <NextDefaultSeo
      titleTemplate={`%s | ${brand.name}`}
      openGraph={{
        type: "website",
        site_name: brand.name,
        images: brand.design?.openGraphImage
          ? [{ url: brand.baseUrl + brand.design.openGraphImage.url }]
          : undefined,
      }}
      languageAlternates={[
        ...alternates,
        { href: xDefault, hrefLang: "x-default" },
      ]}
      canonical={`https://${brand.currentHost}/${locale}${asPath
        .split("?")[0]
        .replace(/\/$/, "")}`} // Remove trailing slash
      twitter={
        brand.social?.twitter
          ? {
              handle: `@${brand.social?.twitter}`,
              site: `@${brand.social?.twitter}`,
              cardType: "summary_large_image",
            }
          : undefined
      }
    />
  );
};

// eslint-disable-next-line no-restricted-imports
export {
  NextSeo as Seo,
  ArticleJsonLd,
  FAQPageJsonLd,
  BreadcrumbJsonLd,
} from "next-seo";

export type LanguageAlternates = Array<{
  href: string;
  hrefLang: string;
}>;
