import { ActionSheet, Button, Link } from "@liberetech/design-system";
import React, {
  HTMLAttributeAnchorTarget,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { useBrand } from "components/Context/BrandContext";
import {
  OnlineReceptionScreenType,
  OnlineReceptionScreenTypeSendMessage,
  useOnlineReception,
} from "components/Context/OnlineReceptionContext";
import { useDevice } from "components/Context/PageContext";
import { useSession } from "components/Context/SessionContext";
import { useTrackingContext } from "components/Context/TrackingContext";
import ListItem from "components/ListItem/ListItem";
import ListItemIcon from "components/ListItem/ListItemIcon";
import ListItemLink from "components/ListItem/ListItemLink";
import { Trans, useTranslation } from "lib/i18n/client";

import styles from "./LayoutOnlineReception.module.css";
import LayoutOnlineReceptionFormAssetSelector from "./LayoutOnlineReceptionFormAssetSelector";
import LayoutOnlineReceptionSendMessageForm from "./LayoutOnlineReceptionFormSendMessage";
import useAssetForCurrentPage from "./useAssetForCurrentPage";
import useOnlineReceptionScope from "./useOnlineReceptionScope";
import useSendMessageContext from "./useSendMessageContext";

const LayoutOnlineReception: React.FC = () => {
  const onlineReception = useOnlineReception();
  const { t } = useTranslation("common");
  const { trackImpression } = useTrackingContext();

  const { currentScreen, navigate, goBack, canGoBack, reset } =
    onlineReception.screenStack;
  const screen = useScreens(currentScreen, { onNavigate: navigate });
  useEffect(() => {
    onlineReception.isOpen &&
      trackImpression({ category: "online_reception", label: "action_sheet" });
    !onlineReception.isOpen &&
      setTimeout(() => {
        reset();
      }, 300);
  }, [onlineReception.isOpen]);

  return (
    <ActionSheet
      className={styles.onlineReception}
      overlayClassName={styles.overlay}
      isOpen={onlineReception.isOpen}
      title={screen.title}
      onRequestClose={() => onlineReception.close()}
      actions={
        (screen.actions || (!!screen.canGoBack && canGoBack)) && (
          <>
            {screen.actions}
            {!!screen.canGoBack && canGoBack && (
              <button onClick={goBack} className={styles.goBack}>
                <Link>{t("onlineReception.goBack")}</Link>
              </button>
            )}
          </>
        )
      }
    >
      <p className={styles.description}>{screen.description}</p>
      {screen.children && (
        <div className={styles.children}>{screen.children}</div>
      )}
      {screen.items && screen.items.length > 0 && (
        <ul className={styles.contactOptions}>
          {screen.items.map((option, i) => {
            const LinkComponent =
              option.href || option.onClick ? ListItemLink : ListItem;
            return (
              <LinkComponent
                key={i}
                title={option.title}
                href={option.href}
                target={option.target}
                onClick={option.onClick}
                startElement={
                  option.icon && <ListItemIcon>{option.icon}</ListItemIcon>
                }
              />
            );
          })}
        </ul>
      )}
    </ActionSheet>
  );
};

const UrgentButton: React.FC<{
  navigate: (screen: OnlineReceptionScreenType) => void;
  number: string | null;
}> = ({ navigate, children, number }) => {
  return (
    <button
      onClick={() =>
        navigate({
          type: number ? "channelsCall" : "channelsCallSelectAsset",
        })
      }
    >
      <Link>{children}</Link>
    </button>
  );
};

const useScreens = (
  currentScreen: OnlineReceptionScreenType,
  options: { onNavigate: (screen: OnlineReceptionScreenType) => void },
): OnlineReceptionScreen => {
  const { t } = useTranslation("common");
  const onlineReception = useOnlineReception();
  const { isKiosk } = useDevice();
  const { session } = useSession();
  const asset = useAssetForCurrentPage();
  const brand = useBrand();
  const sendMessageSubmitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<{
    id: string;
    phone: string;
  } | null>(null);
  const number = asset?.phone || selectedAsset?.phone || null;
  const sendMessageContext = useSendMessageContext();
  const { onNavigate } = options;
  const scope = useOnlineReceptionScope();
  const screen =
    scope === "stay" && currentScreen.type === "start"
      ? ({ type: "channels" } as OnlineReceptionScreenType)
      : currentScreen;

  const faqUrl = `/${t("common:routes.onlineReception")}`;

  const screensItems = {
    start: [
      {
        title: t("onlineReception.start.newReservation"),
        onClick: () => {
          onNavigate({
            type:
              scope === "directChannel"
                ? "newReservation"
                : "newReservationWeb",
          });
          window.dataLayer.push({
            event: "i_want_to_make_a_reservation",
            event_category: "online_reception",
          });
        },
      },
      {
        title: t("onlineReception.start.helpWithReservation"),
        onClick: () => {
          onNavigate({
            type:
              scope === "directChannel" ||
              (scope === "faq" && !session?.inhabitantId)
                ? "redirectToMyPlace"
                : "channels",
          });
          window.dataLayer.push({
            event: "i_have_a_reservation",
            event_category: "online_reception",
          });
        },
      },
    ],
    newReservation: [
      {
        title: t("onlineReception.newReservation.web"),
        icon: <IconStar />,
        onClick: () => {
          onNavigate({ type: "newReservationWeb" });
          window.dataLayer.push({
            event: "make_a_reservation_via_web",
            event_category: "online_reception",
          });
        },
      },
      ...(scope === "directChannel"
        ? [
            {
              title: t("onlineReception.newReservation.phone"),
              icon: <IconPhone />,
              onClick: () => {
                onNavigate({
                  type: number
                    ? "newReservationPhone"
                    : "newReservationSelectAsset",
                });
                window.dataLayer.push({
                  event: "see_phone_number",
                  event_category: "online_reception",
                });
              },
            },
            {
              title: t("onlineReception.newReservation.faq"),
              icon: <IconFAQ />,
              href: faqUrl,
              onClick: () => {
                onlineReception.close();
                window.dataLayer.push({
                  event: "still_have_questions",
                  event_category: "online_reception",
                });
              },
            },
          ]
        : []),
    ],
    newReservationWeb: [
      {
        title: t("onlineReception.newReservationWeb.bookNow"),
        icon: <IconStar />,
        href: "/",
        onClick: () => {
          onlineReception.close();
          window.dataLayer.push({
            event: "go_to_web_reservation",
            event_category: "online_reception",
          });
        },
      },
    ],
    newReservationPhone: [
      {
        title: t("onlineReception.newReservationPhone.call", { number }),
        icon: <IconPhone />,
        href: isKiosk ? undefined : `tel:${number}`,
        target: isKiosk ? undefined : "_blank",
        onClick: () => {
          window.dataLayer.push({
            event: "call",
            event_category: "online_reception",
          });
        },
      },
    ],
    redirectToMyPlace: [
      {
        title: t("onlineReception.redirectToMyPlace.access"),
        href: "/myplace",
        onClick: () => {
          onlineReception.close();
          window.dataLayer.push({
            event: "go_to_my_reservation",
            event_category: "online_reception",
          });
        },
      },
    ],
    channels: [
      ...(scope !== "faq"
        ? [
            {
              title: t("onlineReception.channels.faq"),
              href: faqUrl,
              icon: <IconFAQ />,
              onClick: () => {
                onlineReception.close();
                window.dataLayer.push({
                  event: "go_to_help_index",
                  event_category: "online_reception",
                });
              },
            },
          ]
        : []),
      ...(brand.phoneWhatsApp
        ? [
            {
              title: t("onlineReception.channels.whatsapp"),
              icon: <IconWhatsApp />,
              onClick: () => {
                onNavigate({ type: "channelsWhatsApp" });
                window.dataLayer.push({
                  event: "see_whatsapp_number",
                  event_category: "online_reception",
                });
              },
            },
          ]
        : [
            {
              title: t("onlineReception.channels.chat"),
              icon: <IconChat />,
              onClick: () => {
                onNavigate({ type: "sendMessage" });
                window.dataLayer.push({
                  event: "open_chat",
                  event_category: "online_reception",
                });
              },
            },
          ]),

      {
        title: t("onlineReception.channels.call"),
        icon: <IconPhone />,
        onClick: () => {
          onNavigate({
            type: number ? "channelsCall" : "channelsCallSelectAsset",
          });
          window.dataLayer.push({
            event: "see_phone_number",
            event_category: "online_reception",
          });
        },
      },
    ],
    channelsCall: [
      {
        title: t("onlineReception.channelsCall.call", { number }),
        icon: <IconPhone />,
        href: isKiosk
          ? undefined
          : `tel:${number || selectedAsset?.phone || ""}`,
        target: isKiosk ? undefined : "_blank",
        onClick: () => {
          window.dataLayer.push({
            event: "call",
            event_category: "online_reception",
          });
        },
      },
    ],
    channelsWhatsApp: [
      {
        title: t("onlineReception.channelsWhatsApp.send", {
          number: brand.phoneWhatsApp,
        }),
        icon: <IconWhatsApp />,
        href:
          isKiosk || !brand.phoneWhatsApp
            ? undefined
            : `https://wa.me/${brand.phoneWhatsApp.replace(/[+\- ]/g, "")}`,
        target: "_blank",
        onClick: () => {
          window.dataLayer.push({
            event: "open_whatsapp",
            event_category: "online_reception",
          });
        },
      },
    ],
  } as {
    [key in OnlineReceptionScreenType["type"]]: OnlineReceptionScreenItem[];
  };

  const screensRest = {
    sendMessageResultSuccess: { canGoBack: false },
    sendMessageResultError: {
      canGoBack: false,
      description: (
        <Trans
          i18nKey={`common:onlineReception.sendMessageResultError.description`}
          components={{
            button: <UrgentButton number={number} navigate={onNavigate} />,
          }}
        />
      ),
    },
    sendMessage: {
      canGoBack: false,
      children: (
        <LayoutOnlineReceptionSendMessageForm
          defaultValues={
            (screen as OnlineReceptionScreenTypeSendMessage).defaultValues
          }
          trackingReason={
            (screen as OnlineReceptionScreenTypeSendMessage).trackingReason
          }
          submitButtonRef={sendMessageSubmitButtonRef}
          sendMessageContext={sendMessageContext}
          onSuccess={() => onNavigate({ type: "sendMessageResultSuccess" })}
          onError={() => onNavigate({ type: "sendMessageResultError" })}
        />
      ),
      actions: (
        <Button buttonRef={sendMessageSubmitButtonRef} form="send-message">
          {t("onlineReception.sendMessage.submit")}
        </Button>
      ),
    },
    newReservationSelectAsset: {
      children: (
        <LayoutOnlineReceptionFormAssetSelector
          id="online-reception-asset"
          onAssetSelected={(asset) => {
            setSelectedAsset(asset);
            onNavigate({ type: "newReservationPhone" });
            window.dataLayer.push({
              event: "select_asset",
              event_category: "online_reception",
            });
          }}
        />
      ),
      actions: (
        <Button form="online-reception-asset">
          {t("onlineReception.newReservationSelectAsset.continue")}
        </Button>
      ),
    },
    channelsCallSelectAsset: {
      children: (
        <LayoutOnlineReceptionFormAssetSelector
          id="online-reception-asset"
          onAssetSelected={(asset) => {
            setSelectedAsset(asset);
            onNavigate({ type: "channelsCall" });
            window.dataLayer.push({
              event: "select_asset",
              event_category: "online_reception",
            });
          }}
        />
      ),
      actions: (
        <Button form="online-reception-asset">
          {t("onlineReception.newReservationSelectAsset.continue")}
        </Button>
      ),
    },
  } as {
    [key in OnlineReceptionScreenType["type"]]: {
      description?: ReactNode;
      children?: ReactNode;
      actions?: ReactNode;
      canGoBack?: boolean;
    };
  };

  return {
    title: t(`onlineReception.${screen.type}.title`),
    description:
      screensRest[screen.type]?.description ??
      t(`onlineReception.${screen.type}.description`),
    items: screensItems[screen.type],
    children: screensRest[screen.type]?.children,
    actions: screensRest[screen.type]?.actions,
    canGoBack: screensRest[screen.type]?.canGoBack,
  };
};

const IconFAQ = () => (
  <svg viewBox="0 0 32 32">
    <path d="M16 31c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15Z" />
    <path d="M16 26a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM11.416 9A5.002 5.002 0 0 1 21 11a5 5 0 0 1-5 5v4" />
    <path d="M16 26a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
  </svg>
);

const IconChat = () => (
  <svg viewBox="0 0 32 32">
    <path d="M13.567 26.003c1.847 1.243 4.272 1.998 6.933 1.998 1.2 0 2.35-.16 3.425-.444L28 30.001v-4.406c1.854-1.443 3-3.415 3-5.594a6.333 6.333 0 0 0-.766-2.999" />
    <path d="M14.5 2C7.044 2 1 6.701 1 12.5c0 2.498 1.127 4.79 3 6.593V26l6.813-3.407c1.173.26 2.407.407 3.687.407C21.956 23 28 18.3 28 12.5 28 6.701 21.956 2 14.5 2Z" />
  </svg>
);

const IconWhatsApp = () => (
  <svg viewBox="0 0 32 32">
    <path
      d="M27.3374 4.64667C24.3297 1.65171 20.3294 0.00151951 16.0672 0C7.28453 0 0.136752 7.10754 0.133696 15.844C0.132168 18.6368 0.866352 21.3628 2.26061 23.7652L0 31.9767L8.44654 29.7733C10.7736 31.0361 13.394 31.7009 16.0604 31.7017H16.0672C24.8484 31.7017 31.997 24.5933 32 15.8569C32.0015 11.6228 30.346 7.6424 27.3374 4.64743V4.64667ZM16.0672 29.0258H16.0619C13.6859 29.0251 11.355 28.3898 9.32133 27.1902L8.83771 26.9046L3.82524 28.2121L5.16297 23.3519L4.8482 22.8535C3.5227 20.7566 2.82213 18.3329 2.82366 15.8448C2.82672 8.58374 8.76741 2.67586 16.0726 2.67586C19.6098 2.67738 22.9347 4.04875 25.4351 6.53847C27.9356 9.02745 29.3116 12.337 29.31 15.8554C29.3069 23.1172 23.3663 29.0251 16.0672 29.0251V29.0258ZM23.3311 19.1626C22.9331 18.9643 20.9758 18.007 20.6106 17.8748C20.2454 17.7426 19.9804 17.6766 19.7152 18.0731C19.4501 18.4697 18.6869 19.3617 18.4547 19.6253C18.2224 19.8897 17.9901 19.9224 17.5921 19.724C17.1941 19.5257 15.9114 19.1079 14.3903 17.7593C13.2069 16.7093 12.4077 15.4132 12.1756 15.0166C11.9433 14.62 12.1511 14.4057 12.3497 14.209C12.5284 14.0312 12.7477 13.7463 12.9471 13.5153C13.1466 13.2843 13.2123 13.1188 13.3452 12.8551C13.4781 12.5907 13.4116 12.3598 13.3124 12.1614C13.213 11.9631 12.417 10.0143 12.0846 9.22192C11.7614 8.45002 11.433 8.55487 11.1892 8.54196C10.957 8.53055 10.6919 8.52828 10.426 8.52828C10.1602 8.52828 9.72924 8.62705 9.36409 9.02365C8.99893 9.4202 7.97059 10.3783 7.97059 12.3263C7.97059 14.2743 9.39698 16.1578 9.59636 16.4222C9.79573 16.6866 12.4039 20.6852 16.3972 22.4007C17.3469 22.8087 18.0887 23.0526 18.667 23.2349C19.6205 23.5365 20.4884 23.494 21.1744 23.3922C21.9392 23.2782 23.5298 22.4341 23.8613 21.5095C24.1929 20.5848 24.1929 19.7916 24.0936 19.6268C23.9943 19.4619 23.7284 19.3624 23.3304 19.1641L23.3311 19.1626Z"
      fill="currentColor"
      stroke="none"
    />
  </svg>
);

const IconPhone = () => (
  <svg viewBox="0 0 32 32">
    <path d="M19 1c6.627 0 12 5.373 12 12M25 31C11.745 31 1 20.255 1 7l5-5 7 7-5 5 10 10 5-5 7 7-5 5ZM25 13a6 6 0 0 0-6-6" />
  </svg>
);

const IconStar = () => (
  <svg viewBox="0 0 32 32">
    <path d="m16 2.686 4.326 8.765L30 12.857l-7 6.823 1.652 9.636L16 24.765l-8.652 4.55L9 19.68l-7-6.822 9.674-1.407L16 2.686Z" />
  </svg>
);

type OnlineReceptionScreen = {
  title: string;
  description: React.ReactNode;
  items: Array<OnlineReceptionScreenItem>;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  canGoBack?: boolean;
};

type OnlineReceptionScreenItem = {
  title: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
};

export default LayoutOnlineReception;
