export const COOKIE_GROUPS_ACCEPTED = "li_track";
export const COOKIE_EXPIRATION = 3600 * 1000 * 24 * 365; // 1 year

export const groupsFromCookie = (): TrackingCookieGroupV2[] | null => {
  if (typeof document === "undefined") {
    return null;
  }

  const match = document.cookie.match(
    new RegExp(`(^| )${COOKIE_GROUPS_ACCEPTED}=([^;]*)`),
  );

  if (!match || match.length < 3) {
    return null;
  }

  if (match[2] === "") {
    return [];
  }

  const groups = (match?.[2] || "").split(",") as (
    | LegacyTrackingCookieGroup
    | TrackingCookieGroupV2
  )[];

  if (
    groups.includes("ad") ||
    groups.includes("analytics") ||
    groups.includes("personalization")
  ) {
    // Has legacy permissions, request again
    return null;
  }

  return groups as TrackingCookieGroupV2[];
};

export type TrackingContextPage = {
  page_type: string;
  inhabitant_id?: string;
  purchase_id?: string;
  reservation_id?: string;
  asset_id?: string;
  attempt_id?: string;
  product_id?: string;
  faq_id?: string;
};

type LegacyTrackingCookieGroup = "ad" | "analytics" | "personalization";

export const allCookieGroupsV2: TrackingCookieGroupV2[] = [
  "analytics_storage",
  "ad_storage",
  "ad_user_data",
  "ad_personalization",
];

export type TrackingCookieGroupV2 =
  | "analytics_storage"
  | "ad_storage"
  | "ad_user_data"
  | "ad_personalization";
