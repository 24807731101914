const LayoutContactIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M12 5.25c-5.25 2.25-6.75 6.525-6.75 10.5v3M12 5.25v-3" />
      <path d="M22.5 15.75c0-5.775-4.725-10.5-10.5-10.5S1.5 9.975 1.5 15.75v3h21v-3ZM1.5 21.75h21M15.75 2.25h-7.5" />
    </svg>
  );
};

export default LayoutContactIcon;
