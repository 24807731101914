import { useState } from "react";

const useActionSelector = <Type>(): {
  currentAction: Type | null;
  isActionOpen: boolean;
  setAction: (action: Type) => void;
  closeAction: () => void;
} => {
  const [currentAction, setActionFn] = useState<Type | null>(null);
  const [isActionOpen, setActionOpen] = useState<boolean>(false);
  const setAction = (action: Type) => {
    setActionFn(action);
    setActionOpen(true);
  };
  const closeAction = () => {
    setActionOpen(false);
    setTimeout(() => setActionFn(null), 300);
  };
  return { currentAction, isActionOpen, setAction, closeAction };
};

export default useActionSelector;
