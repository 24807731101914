import { useState } from "react";

const useScreenStack = <T>(
  initialScreen: T,
): {
  currentScreen: T;
  navigate: (screen: T) => void;
  goBack: () => void;
  canGoBack: boolean;
  reset: () => void;
} => {
  const [screenStack, setScreenStack] = useState<T[]>([initialScreen]);

  return {
    currentScreen: screenStack[screenStack.length - 1],
    navigate: (screen) => {
      setScreenStack([...screenStack, screen]);
    },
    goBack: () => {
      setScreenStack(screenStack.slice(0, screenStack.length - 1));
    },
    canGoBack: screenStack.length > 1,
    reset: () => setScreenStack([initialScreen]),
  };
};

export default useScreenStack;
