import { Router } from "next/router";
import nProgress from "nprogress";
import { useEffect } from "react";

import styles from "./LayoutProgressBar.module.css";

const routeChangeStart = () => {
  nProgress.start();
};

const routeChangeEnd = () => {
  nProgress.done();
};

const ProgressBar: React.FC = () => {
  useEffect(() => {
    nProgress.configure({
      template: `<div class="${styles.progressBar}" role="bar"></div>`,
    });
    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeEnd);
    Router.events.on("routeChangeError", routeChangeEnd);

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeEnd);
      Router.events.off("routeChangeError", routeChangeEnd);
    };
  }, []);

  return null;
};

export default ProgressBar;
