import { FormInput } from "@liberetech/design-system";
import FormTextarea from "@liberetech/design-system/FormTextarea/FormTextarea";
import React, { MutableRefObject, useEffect } from "react";

import { SendMessageTrackingReason } from "components/Context/OnlineReceptionContext";
import { SendMessageContext } from "components/Layout/LayoutOnlineReception/useSendMessageContext";
import { bffClient } from "lib/client/bff";
import { useLanguage } from "lib/i18n/client";
import { captureError } from "lib/logger";
import useForm from "lib/useForm";
import { email } from "lib/validations";

import styles from "./LayoutOnlineReceptionFormSendMessage.module.css";

const FormSendMessage: React.FC<SendMessageActionProps> = ({
  defaultValues,
  sendMessageContext,
  trackingReason,
  submitButtonRef,
  onSuccess,
  onError,
}) => {
  const lang = useLanguage();
  const { inputProps, handleSubmit, formState } = useForm<{
    title: string;
    description: string;
    email: string | undefined;
    categoryIds: string[] | undefined;
  }>({
    translationContext: "common:onlineReception.sendMessage.form.",
    defaultValues: {
      title: defaultValues?.title,
      description: defaultValues?.description,
      categoryIds: defaultValues?.categoryIds,
    },
  });

  useEffect(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.disabled = formState.isSubmitting;
    }
  }, [formState.isSubmitting]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      window.dataLayer.push({
        event: "click",
        event_category: "send-message",
        event_label: trackingReason ?? "generic-help",
      });
      await bffClient(lang).sendMessage(
        data.title,
        data.description,
        data.email,
        data.categoryIds,
        {
          purchase_id: sendMessageContext.purchaseId,
          reservation_id: sendMessageContext.reservationId,
          chain_id: sendMessageContext.chainId,
          brand_id: sendMessageContext.brandId,
        },
      );
      onSuccess();
    } catch (err) {
      captureError(err);
      onError();
    }
  });
  return (
    <form id={"send-message"} onSubmit={onSubmit} className={styles.form}>
      <FormInput
        disabled={!!defaultValues?.title}
        {...inputProps("title", {
          required: true,
          maxLength: {
            value: 255,
            message: "validation.titleMaxLength",
          },
        })}
      />
      <FormTextarea {...inputProps("description", { required: true })} />
      {!sendMessageContext.isLoggedIn && (
        <FormInput
          {...inputProps("email", {
            required: true,
            validate: { email },
          })}
        />
      )}
    </form>
  );
};

export type SendMessageActionProps = {
  defaultValues?: {
    title?: string;
    description?: string;
    categoryIds?: string[];
  };
  sendMessageContext: SendMessageContext;
  submitButtonRef: MutableRefObject<HTMLButtonElement | null>;
  trackingReason?: SendMessageTrackingReason;
  onSuccess: () => void;
  onError: () => void;
};

export default FormSendMessage;
