import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions as RHRegisterOptions,
  useForm as useRHForm,
  UseFormProps,
  UseFormRegisterReturn,
  UseFormReturn,
} from "react-hook-form";

import { useTranslation } from "lib/i18n/client";

const errorMessageForPath = (
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<any>,
): string | undefined => {
  const error = path.split(".").reduce(
    (prev, key) => (typeof prev === "object" ? prev[key] : undefined),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors as any,
  );
  return error?.message;
};

const useForm: UseForm = ({ translationContext, ...options }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps = useRHForm<any>(options);
  const [namespace, prefix] = translationContext.split(":");
  const { t } = useTranslation(namespace);
  const tValidation = (field: string): string | undefined => {
    const message = errorMessageForPath(field, useFormProps.formState.errors);

    return message
      ? message.includes(":")
        ? t(message)
        : t(`${prefix || ""}${message}`)
      : undefined;
  };

  return {
    ...useFormProps,
    handleSubmit: (onValid, onInvalid) => async (e) => {
      useFormProps.handleSubmit(onValid, (err, ev) => {
        const isIOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        if (isIOS && Object.keys(err).length > 0) {
          const errorRef = err[Object.keys(err)[0]]?.ref as HTMLInputElement;
          if (errorRef?.scrollIntoView) {
            errorRef?.scrollIntoView();
          }
        }

        if (onInvalid) {
          onInvalid(err, ev);
        }
      })(e);
    },
    inputProps: (name, fieldOptions = {}) => {
      const { label, ...rhFieldOptions } = fieldOptions;
      const { ref, ...registerProps } = useFormProps.register(name, {
        ...rhFieldOptions,
        required: fieldOptions?.required && "common:validation.required",
      });
      return {
        label: label || t(`${prefix || ""}${name}`),
        error: tValidation(name),
        required: !!fieldOptions?.required,
        inputRef: ref,
        ...registerProps,
      };
    },
    inputPropsForRadio: (name, values, fieldOptions = {}) => {
      const { label, ...rhFieldOptions } = fieldOptions;
      return {
        label: label || t(`${prefix || ""}${name}`),
        error: tValidation(name),
        required: !!fieldOptions?.required,
        options: values.map((value) => ({
          ...value,
          ...useFormProps.register(name, {
            ...rhFieldOptions,
            required: fieldOptions?.required && "common:validation.required",
          }),
        })),
      };
    },
    controlledProps: (name, fieldOptions = {}) => {
      const { label, ...rhFieldOptions } = fieldOptions;
      return {
        label: label || t(`${prefix || ""}${name}`),
        name,
        error: tValidation(name),
        required: !!fieldOptions?.required,
        control: useFormProps.control,
        rules: {
          ...rhFieldOptions,
          required: fieldOptions?.required && "common:validation.required",
        },
      };
    },
  };
};

export default useForm;

type UseForm = <T extends FieldValues>(
  options: UseFormProps<T> & { translationContext: string },
) => UseFormReturn<T> & {
  inputProps: InputProps;
  inputPropsForRadio: InputPropsForRadio;
  controlledProps: ControlledProps;
};

export type InputProps = <T extends FieldValues>(
  name: Path<T>,
  options?: RegisterOptions,
) => {
  label: string;
  error?: string;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: (ref: any) => void;
} & Omit<UseFormRegisterReturn, "ref">;

export type InputPropsForRadio = <T extends FieldValues>(
  name: Path<T>,
  values: Array<
    {
      label: string;
      value: string;
    } & React.InputHTMLAttributes<HTMLInputElement>
  >,
  options?: RegisterOptions,
) => {
  label: string;
  error?: string;
  required?: boolean;
  options: Array<
    {
      label: string;
      value: string;
    } & UseFormRegisterReturn &
      React.InputHTMLAttributes<HTMLInputElement>
  >;
};

export type ControlledProps = <T extends FieldValues>(
  name: Path<T>,
  options?: RegisterOptions,
) => {
  label: string;
  name: string;
  error?: string;
  required?: boolean;
  control: Control<T>;
  rules: RegisterOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: (ref: any) => void;
};

type RegisterOptions = RHRegisterOptions & { label?: string };
