import fetchAPI from "isomorphic-fetch";

const baseHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const fetch = (query: string, host: string): Promise<Record<string, unknown>> =>
  new Promise((resolve, reject) =>
    fetchAPI(host, {
      method: "POST",
      headers: baseHeaders,
      body: JSON.stringify({ query }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.errors?.length > 0) {
          throw new Error(json.errors[0].message);
        }
        return resolve(json.data);
      })
      .catch((err) => reject(err)),
  );

export const cmsClientSwr =
  (host: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (query: string): Promise<any> => {
    return await fetch(query, host);
  };

export default fetch;
