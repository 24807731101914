import { useEffect, useContext, createContext, useState } from "react";

// eslint-disable-next-line no-restricted-imports
import {
  isKiosk as deviceIsKiosk,
  isNativeApp as deviceIsNativeApp,
  nativeAppVersion as deviceNativeAppVersion,
  notifyNativeApp as deviceNotifyNativeApp,
  canShare as deviceCanShare,
} from "lib/device";

const PageContext = createContext<ContextType | null>(null);

export const Provider: React.FC = ({ children }) => {
  const [page, setPage] = useState<PageType | null>(null);

  useState<boolean>(false);
  const [searchBarVisible, setSearchBarVisible] = useState<boolean>(false);

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        searchBar: {
          isOpen: searchBarVisible,
          open: () => {
            setSearchBarVisible(true);
          },
          close: () => {
            setSearchBarVisible(false);
          },
        },
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const Page: React.FC<PageType> = (page) => {
  const context = useContext(PageContext);

  useEffect(() => {
    context?.setPage(page);

    return () => {
      context?.setPage(null);
    };
  }, [page]);

  return null;
};

export const usePage = (): PageType | null => {
  const context = useContext(PageContext);

  return context?.page || null;
};

export const useSearchBar: UseSearchBarType = () => {
  const context = useContext(PageContext);

  return context
    ? context.searchBar
    : { isOpen: false, open: () => null, close: () => null };
};

export const useDevice: UseDeviceType = () => {
  const [isKiosk, setIsKiosk] = useState<boolean>(false);
  const [isNativeApp, setIsNativeApp] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(false);
  const [nativeAppVersion, setNativeAppVersion] = useState<string>("");

  useEffect(() => {
    setIsKiosk(deviceIsKiosk());
    setIsNativeApp(deviceIsNativeApp());
    setCanShare(deviceCanShare());
    setNativeAppVersion(deviceNativeAppVersion() || "");
  }, []);

  return {
    isKiosk,
    isNativeApp,
    canShare,
    nativeAppVersion: nativeAppVersion,
    notifyNativeApp: deviceNotifyNativeApp,
  };
};

type ContextType = {
  page: PageType | null;
  setPage: (page: PageType | null) => void;
  searchBar: {
    open: () => void;
    close: () => void;
    isOpen: boolean;
  };
};

type PageType = {
  title?: string;
  backHref?: string;
  backOnClick?: () => void;
};

type UseSearchBarType = () => {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

type UseDeviceType = () => {
  isKiosk: boolean;
  isNativeApp: boolean;
  canShare: boolean;
  nativeAppVersion: string;
  notifyNativeApp: (
    message: string,
    payload?: { [key: string]: unknown },
  ) => void;
};
