import { isBefore, isAfter } from "date-fns";
import { Validate } from "react-hook-form";

import { Locale } from "types";

import { toApiDate } from "./formatter/date";

export const email: Validate<string, unknown> = (value) => {
  if (!value) {
    return true;
  }

  return /^\S+@\S+\.\S+$/.test(value) || "common:validation.email";
};

export const date =
  (locale: Locale, required = true): Validate<string, unknown> =>
  (value) => {
    if (!required && !value) {
      return true;
    }
    return toApiDate(value, locale) === null ? "common:validation.date" : true;
  };

export const dateAfter =
  (date: Date, locale: Locale, required = true): Validate<string, unknown> =>
  (value) => {
    if (!required && !value) {
      return true;
    }
    const apiDate = toApiDate(value, locale);
    if (!apiDate) {
      return "common:validation.date";
    }
    return isAfter(new Date(apiDate), date) || "common:validation.dateAfter";
  };

export const datePast =
  (locale: Locale, required = true): Validate<string, unknown> =>
  (value) => {
    if (!required && !value) {
      return true;
    }
    const apiDate = toApiDate(value, locale);
    if (!apiDate) {
      return "common:validation.date";
    }
    return (
      isBefore(new Date(apiDate), new Date()) || "common:validation.datePast"
    );
  };
