import { createContext, useContext, useState } from "react";

import useScreenStack from "components/Layout/LayoutOnlineReception/useScreenStack";

const OnlineReceptionContext = createContext<OnlineReceptionContextType>({
  open: () => null,
  close: () => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigate: (screen: OnlineReceptionScreenType) => null,
  screenStack: {
    currentScreen: { type: "start" } as OnlineReceptionScreenType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    navigate: (screen: OnlineReceptionScreenType) => null,
    goBack: () => null,
    canGoBack: false,
    reset: () => null,
  },
  isOpen: false,
});

export type SendMessageTrackingReason =
  | "generic-help"
  | "login-help"
  | "reservation-cancellation"
  | "reservation-modify-inhabitant-count"
  | "reservation-modification"
  | "reservation-internal-checkin-issue"
  | "reservation-access-issue"
  | "purchase-pay-amount-too-small";

export type OnlineReceptionScreenTypeSendMessage = {
  type: "sendMessage";
  trackingReason?: SendMessageTrackingReason;
  defaultValues?: {
    title?: string;
    description?: string;
    categoryIds?: string[];
  };
};

export type OnlineReceptionScreenType =
  | {
      type:
        | "start"
        | "newReservation"
        | "newReservationWeb"
        | "newReservationPhone"
        | "newReservationSelectAsset"
        | "redirectToMyPlace"
        | "channels"
        | "channelsCall"
        | "channelsCallSelectAsset"
        | "channelsWhatsApp"
        | "sendMessageResultSuccess"
        | "sendMessageResultError";
    }
  | OnlineReceptionScreenTypeSendMessage;

export const Provider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const screenStack = useScreenStack<OnlineReceptionScreenType>({
    type: "start",
  });
  return (
    <OnlineReceptionContext.Provider
      value={{
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        navigate: (screen: OnlineReceptionScreenType) => {
          screenStack.navigate(screen);
          setIsOpen(true);
        },
        screenStack,
        isOpen,
      }}
    >
      {children}
    </OnlineReceptionContext.Provider>
  );
};

export const useOnlineReception: () => OnlineReceptionContextType = () =>
  useContext(OnlineReceptionContext);

type OnlineReceptionContextType = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  navigate: (screen: OnlineReceptionScreenType) => void;
  screenStack: {
    currentScreen: OnlineReceptionScreenType;
    navigate: (screen: OnlineReceptionScreenType) => void;
    goBack: () => void;
    canGoBack: boolean;
    reset: () => void;
  };
};

export default OnlineReceptionContext;
